import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';
import CustomerReviews from '../components/services/reviews';
import HeroBanner from '../components/services/hero-banner';
import Breadcrumb from '../components/services/breadcrumb';
import Intro from '../components/services/intro';
import SearchForm from '../components/services/search-form';
import Faqs from '../components/services/faqs';
import MoreInfo from '../components/services/more-info';
import CallToAction from '../components/services/call-to-action';
import '../assets/styles/templates/sevices-list.scss';

const States = ({ data }) => {
  let pageData = data.seocarstate;
  if (pageData === null) {
    pageData = data.seoservicestate;
  }
  const { seo, breadcrumbs, states, reviews, faq, jumbotron, moreInfo, exploreInfo, aboutInfo, searchText, quoteInfo, searchPlaceholder, structuredData } = pageData;

  return (
    <Layout wrapperClass="standard-page service-list-wrapper" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData={structuredData}
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <AnouncementBar />
      <HeroBanner subHeading={jumbotron.header} title={jumbotron.body} btnText={jumbotron.button} />
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <SearchForm searchTitle={searchText} placeholderCopy={searchPlaceholder} />
      <div className="container">
        {exploreInfo && (
          <Intro title={exploreInfo.header} text={exploreInfo.body} classWrapper="intro" />
        )}
        <div className="row">
          {states.map((node) => (
            <Link to={node.url} className="service-tile col-3 text-center" key={node.state}>
              <div className="card">
                <div className="card-body">
                  {node.photoLink.local && (
                    <div className="service-tile-image">
                      <Img fluid={node.photoLink.local.childImageSharp.fluid} />
                    </div>
                  )}
                  <h3>{node.state}</h3>
                </div>
              </div>
            </Link>
          ))}
        </div>
        {moreInfo && (
          <MoreInfo moreCTA={moreInfo.title} moreText={moreInfo.body} moreTitle={moreInfo.header} />
        )}
        {aboutInfo && (
          <Intro title={aboutInfo.header} text={aboutInfo.body} classWrapper="outro" />
        )}
      </div>
      {quoteInfo && (
        <CallToAction callToAction={quoteInfo} />
      )}
      {faq && (
        <Faqs title={faq.header} faqs={faq.faqs} />
      )}
      <CustomerReviews reviews={reviews} />
    </Layout>
  );
};

export const query = graphql`
  query StateQuery($url: String!) {
    seocarstate(url: { eq: $url }) {
      url
      searchPlaceholder
      breadcrumbs {
        text
        url
      }
      car
      exploreInfo {
        body
        header
        title
      }
      jumbotron {
        body
        button
        header
      }
      moreInfo {
        body
        header
        title
      }
      reviews {
        customerName
        fullName
        pbName
        rating
        ratingOverall
        reviewDate
        reviewText
        title
      }
      searchText
      structuredData
      seo {
        canonical
        description
        heading
        og {
          description
          image
          title
          type
          url
        }
        title
      }
      states {
        photoLink {
          local {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        state
        url
        alt
      }
      title
    }
    seoservicestate(url: { eq: $url }) {
      searchPlaceholder
      url
      aboutInfo {
        body
        header
      }
      quoteInfo {
        header
        button
      }
      breadcrumbs {
        text
        url
      }
      exploreInfo {
        body
        header
        title
      }
      faq {
        faqs {
          answer
          question
        }
        header
      }
      jumbotron {
        body
        button
        header
      }
      reviews {
        customerName
        fullName
        pbName
        rating
        ratingOverall
        reviewDate
        reviewText
        title
      }
      searchText
      structuredData
      seo {
        canonical
        description
        heading
        og {
          description
          image
          title
          type
          url
        }
        title
      }
      service
      states {
        photoLink {
          local {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        state
        url
        alt
      }
      title
    }
  }
`;

States.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default States;
